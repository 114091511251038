import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Lottie from 'lottie-react'
import EventBus from '../Shared/EventBus'
import SubmitButton from '../ChatMessage/SubmitButton'
import NinInput from './Input/NinInput'
import IdCardInput from './Input/IdCardInput'
import PincodeInput from './Input/PincodeInput'
import fingerprintAnimation from '../../../images/fingerprint.json'

// TODO FIX ODESÍLÁNÍ PRÁZDNÝCH FORMULÁŘŮ

function AuthenticationForm(props) {
    const { chatUuid, closeForm } = props
    const submitAnimation = useRef(null)
    const formFinishedRef = useRef(false)
    
    const [formLoading, setFormLoading] = useState(false)
    const [formSuccess, setFormSuccess] = useState(false)
    const [formError, setFormError] = useState(false)
    const [step, setStep] = useState(1)
    
    const [nin, setNin] = useState('')
    const [identityCardNumber, setIdentityCardNumber] = useState('')
    const [pinCode, setPinCode] = useState('')

    useEffect(() => {
        EventBus.on('authenticationSuccess', handleSuccess)
        submitAnimation.current.goToAndPlay(0, true)
    }, [])

    useEffect(() => {
        return () => {
            if (!formFinishedRef.current) closeForm()
        }
    }, [])

    const handleFormSubmit = () => {
        if (formLoading || formFinishedRef.current) return

        if (step === 1) {
            setFormLoading(true)

            axios
                .post('/chat/action/authenticate', {
                    chatUuid,
                    identityCardNumber,
                    nin: nin.replace(/[^0-9]/, ''),
                })
                .then((response) => {
                    if (response.status === 200) {
                        setFormError(false)
                        setFormLoading(false)
                        setStep(2)
                        submitAnimation.current.goToAndPlay(20, true)
                    }
                })
                .catch((e) => {
                    console.warn(e)
                    setFormError(true)
                    setFormLoading(false)
                })
        }
        if (step === 2) {
            setFormLoading(true)

            axios
                .post('/chat/action/authenticate/validate', {
                    chatUuid,
                    pinCode,
                    nin: nin.replace(/[^0-9]/, ''),
                })
                .then((response) => {
                    if (response.status === 200) {
                        setFormError(false)
                        setFormLoading(false)
                        // setFormSuccess(true) TODO ...
                    }
                })
                .catch((e) => {
                    console.warn(e)
                    setFormError(true)
                    setFormLoading(false)
                })
        }
    }

    const handleSuccess = () => {
        formFinishedRef.current = true
        submitAnimation.current.goToAndPlay(36, true)

        setTimeout(() => {
            EventBus.emit('form-close')
            EventBus.emit('received-message', {
                text: '✅ Děkujeme, nyní jste identifikován',
                type: 'system',
            })
        }, 1700)

        setTimeout(() => submitAnimation.current.goToAndPlay(0), 1800)
    }

    const handleIdentityCardNumberChange = (idCardNumber) => {
        const idCardValidRegex = /^(?:[A-Za-z]{0,2}\d{0,6}|\d{0,9})$/
        idCardValidRegex.test(idCardNumber) && setIdentityCardNumber(idCardNumber)
    }

    const handleNinChange = (nin) => setNin(nin)

    const handlePinCodeChange = (pinCode) => setPinCode(pinCode)

    const frameEnter = (data) => {
        const frame = Math.floor(data.currentTime * 100) / 100
        if (step === 1 && frame > 18 && frame < 20) {
            submitAnimation.current.pause()
        }

        if (step === 2 && frame > 34 && frame < 36) {
            submitAnimation.current.pause()
        }
    }

    return (
        <form>
            <div className="form__content">
                <div className="content-wrapper">
                    <div className="image-wrapper">
                        <div style={{ width: '100%' }}>
                            <Lottie animationData={fingerprintAnimation} loop={false} autoplay onEnterFrame={frameEnter} lottieRef={submitAnimation} />
                        </div>
                    </div>

                    {step === 1 && (
                        <div>
                            <p className="text-center mb-1">Zadejte Vaše rodné číslo</p>
                            <NinInput onSubmit={handleFormSubmit} onChange={handleNinChange} value={nin} />
                            <p className="text-center my-1 mb-1">Zadejte č. občanského průkazu </p>
                            <IdCardInput onSubmit={handleFormSubmit} onChange={handleIdentityCardNumberChange} value={identityCardNumber} />
                            {formError && <small className="text-center error">Kombinace údajů není správná</small>}
                        </div>
                    )}
                    {step === 2 && (
                        <div className="pincode-wrapper">
                            <p className="text-center mt-1">Zadejte sms kód</p>
                            <PincodeInput onChange={handlePinCodeChange} onSubmit={handleFormSubmit} value={pinCode} />
                            {formError && <small className="text-center error">Kód není správný.</small>}
                        </div>
                    )}
                </div>
            </div>
            {!formSuccess && <SubmitButton onClick={handleFormSubmit} preventInput={formLoading} />}
        </form>
    )
}

export default AuthenticationForm
